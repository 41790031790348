import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { IoIosBed } from "@react-icons/all-files/io/IoIosBed"
import { FaBath } from "@react-icons/all-files/fa/FaBath"
import { map, compact, uniq, cloneDeep } from "lodash"
import { FaRulerCombined } from "@react-icons/all-files/fa/FaRulerCombined"
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt"
import ReactPaginate from "react-paginate"
import "../pages/places.css"
import InputSlider from "../components/inputSlider/inputSlider"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Search = ({
  allPosts,
  placesTitle,
  allLocation,
  location,
  searchTitleCaption,
  minPriceSliderValue,
  maxPriceSliderValue,
  priceStepSliderValue,
}) => {
  let selectedMlsAreaMajor
  if (location.search) {
    const params = new URLSearchParams(location.search)
    selectedMlsAreaMajor = params.get("community")
  }

  const allLocations = {}
  const allNeighborhoodsByArea = {}
  let allParkingTypes = []
  let allArchitecturalStyles = []

  allPosts.forEach((element, index) => {
    if (allLocations.hasOwnProperty(element["MLSAreaMajor"])) {
      allLocations[element["MLSAreaMajor"]].push(element["MLSAreaMinor"])
    } else {
      allLocations[element["MLSAreaMajor"]] = [element["MLSAreaMinor"]]
    }

    if (allNeighborhoodsByArea.hasOwnProperty(element["MLSAreaMinor"])) {
      allNeighborhoodsByArea[element["MLSAreaMinor"]].push(
        element["SubdivisionName"]
      )
    } else {
      allNeighborhoodsByArea[element["MLSAreaMinor"]] = [
        element["SubdivisionName"],
      ]
    }

    if (!element.BedroomsTotal) {
      allPosts[index].BedroomsTotal = 0
    }

    element.ParkingFeatures &&
      element.ParkingFeatures.forEach(parkingElement =>
        allParkingTypes.push(parkingElement)
      )
    element.ArchitecturalStyle &&
      element.ArchitecturalStyle.forEach(architectureElement =>
        allArchitecturalStyles.push(architectureElement)
      )
  })

  allParkingTypes = uniq(compact(allParkingTypes)).sort()
  allArchitecturalStyles = uniq(compact(allArchitecturalStyles)).sort()

  Object.getOwnPropertyNames(allLocations).forEach(location => {
    allLocations[location] = uniq(compact(allLocations[location]))
  })

  Object.getOwnPropertyNames(allNeighborhoodsByArea).forEach(location => {
    allNeighborhoodsByArea[location] = uniq(
      compact(allNeighborhoodsByArea[location])
    )
  })

  const allTypes = uniq(compact(map(allPosts, "PropertyType")))
    .sort()
    .reverse()

  let initialAreas = []
  let initialArea = ""
  let initialNeighborhoods = []
  let initialNeighborhood = ""

  const [dynamicLocation, setDynamicLocation] = useState("location")
  const [dynamicArea, setdynamicArea] = useState("area")
  const [dynamicNeighbor, setdynamicNeighbor] = useState("neighbor")
  const [dynamicType, setdynamicType] = useState("type")
  const [dynamicStyle, setdynamicStyle] = useState("style")
  const [dynamicGarage, setdynamicGarage] = useState("")

  useEffect(() => {
    if (selectedMlsAreaMajor || (location.state && location.state.homeFilter)) {
      const mlsAreaToEvaluate = selectedMlsAreaMajor
        ? selectedMlsAreaMajor
        : location.state.homeFilter.MLSAreaMajor[0]

      if (mlsAreaToEvaluate === "Snowmass Village") {
        setDynamicLocation("snow-village")
      } else if (mlsAreaToEvaluate === "Woody Creek") {
        setDynamicLocation("wood-creek")
      } else if (mlsAreaToEvaluate === "Old Snowmass") {
        setDynamicLocation("old-snow")
      } else if (mlsAreaToEvaluate === "Missouri Heights") {
        setDynamicLocation("missouri")
      } else if (mlsAreaToEvaluate === "Carbondale") {
        setDynamicLocation("carbondale")
      } else if (mlsAreaToEvaluate === "Redstone/Crystal Valley") {
        setDynamicLocation("redstone")
      } else if (mlsAreaToEvaluate === "Glenwood") {
        setDynamicLocation("glenwood")
      } else if (mlsAreaToEvaluate === "Brush Creek Village") {
        setDynamicLocation("brush-village")
      } else {
        setDynamicLocation("location")
      }

      if (mlsAreaToEvaluate === "") {
        setdynamicArea("area")
      } else {
        setdynamicArea("")
      }
    }
  }, [])

  const getFunctionBySortName = sortName => {
    switch (sortName) {
      case "date_recently_added":
        return {
          func: (a, b) => {
            return b.OnMarketDate.localeCompare(a.OnMarketDate)
          },
          title: sortName,
        }
      case "price_low":
        return {
          func: (a, b) => {
            return a.ListPrice - b.ListPrice
          },
          title: sortName,
        }
      case "price_high":
      default:
        return {
          func: (a, b) => {
            return b.ListPrice - a.ListPrice
          },
          title: sortName,
        }
    }
  }

  let initialSortBy = "price_high"
  let initialPage = 0

  if (
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("places_state" + location.key)
  ) {
    let savedState = JSON.parse(
      localStorage.getItem("places_state" + location.key)
    )
    initialAreas = allLocations[savedState.filter.MLSAreaMajor]
    initialNeighborhoods =
      allNeighborhoodsByArea[savedState.filter.MLSAreaMinor]
    initialSortBy = savedState.sortBy
    initialPage = savedState.page
    if (!initialAreas) {
      initialAreas = []
    }
    if (!initialNeighborhoods) {
      initialNeighborhoods = []
    }
    initialArea = savedState.selectedArea
    initialNeighborhood = savedState.selectedNeighborhood
  } else if (selectedMlsAreaMajor) {
    initialAreas = allLocations[selectedMlsAreaMajor]

    if (!initialAreas) {
      initialAreas = []
    }
  } else if (location.state) {
    if (
      location.state.homeFilter &&
      location.state.homeFilter.MLSAreaMajor &&
      location.state.selectedArea
    ) {
      initialAreas = allLocations[location.state.homeFilter.MLSAreaMajor]
      initialArea = location.state.selectedArea
    }
  }

  const [allAreas, setAllAreas] = useState(initialAreas)
  const [selectedArea, setArea] = useState(initialArea)

  const [allNeighborhoods, setAllNeighborhoods] = useState(initialNeighborhoods)
  const [selectedNeighborhood, setNeighborhood] = useState(initialNeighborhood)

  const [selectedSortBy, setSortBy] = useState(
    getFunctionBySortName(initialSortBy)
  )

  let powerSearchToggle = false
  if (
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("places_state" + location.key)
  ) {
    powerSearchToggle = JSON.parse(
      localStorage.getItem("places_state" + location.key)
    ).showAdvancedFilter
  } else if (location.state) {
    if (location.state.powerSearch) {
      powerSearchToggle = true
    }
  }

  let [showAdvancedFilter, setShowAdvancedFilter] = useState(powerSearchToggle)

  const toggleAdvancedFilterDisplay = () => {
    setShowAdvancedFilter(!showAdvancedFilter)
  }

  const emptyQuery = {}
  let emptyFilter = {
    ListingId: [],
    ListOfficeMlsId: [],
    MLSAreaMajor: [],
    MLSAreaMinor: [],
    ListPrice: {
      min: null,
      max: null,
    },
    BedroomsTotal: {
      min: null,
      max: null,
    },
    BathroomsTotalInteger: {
      min: null,
      max: null,
    },
    LivingArea: {
      min: null,
      max: null,
    },
    LotSizeAcres: {
      min: null,
      max: null,
    },
    SubdivisionName: [],
    PropertyType: [],
    ArchitecturalStyle: [],
    ParkingFeatures: [],
    PropertyCondition: [],
    StandardStatus: [],
    VirtualTourURLUnbranded: [],
    Keyword: "",
  }
  let initialQuery = emptyQuery
  let initialFilter = emptyFilter

  const buildFilter = filter => {
    let query = {}
    for (let keys in filter) {
      if (
        (keys === "Keyword" && (filter[keys]?.trim().length ?? 0) > 0) ||
        filter[keys].constructor === Object ||
        (filter[keys].constructor === Array && filter[keys].length > 0)
      ) {
        query[keys] = filter[keys]
      }
    }
    return query
  }

  const filterData = (data, query) => {
    const keysWithMinMax = [
      "ListPrice",
      "BedroomsTotal",
      "BathroomsTotalInteger",
      "LivingArea",
      "LotSizeAcres",
    ]
    const filteredData = data.filter(item => {
      for (let key in query) {
        switch (key) {
          case "ArchitecturalStyle":
          case "ParkingFeatures":
          case "PropertyCondition":
            if (!item[key]) {
              return false
            } else if (item[key].length === 1) {
              if (item[key][0] !== query[key][0]) {
                return false
              } else {
                break
              }
            } else if (item[key].length > 1) {
              for (let i = 0; i < item[key].length; i++) {
                if (item[key][i] === query[key][0]) {
                  return true
                }
              }
              return false
            }
            break
          /*case "ListingId":
            if (!item[key]) {
              return false
            } else if (query[key].length > 0) {
              for (let i = 0; i < query[key][0].length; i++) {
                if (query[key][0][i] !== item[key].toString()[i]) {
                  return false
                }
              }
              break
            }
            break*/
          case "VirtualTourURLUnbranded":
            if (!item[key]) {
              return false
            } else {
              break
            }
          case "Keyword":
            if ((query[key]?.trim().length ?? 0) === 0) {
              return false
            }
            var regex = new RegExp(query[key], "gi")
            if (
              (!item.ListingId ||
                (item.ListingId.match(regex)?.length ?? 0) == 0) &&
              (!item.UnparsedAddress ||
                (item.UnparsedAddress.match(regex)?.length ?? 0) == 0) &&
              (!item.MLSAreaMajor ||
                (item.MLSAreaMajor.match(regex)?.length ?? 0) == 0) &&
              (!item.MLSAreaMinor ||
                (item.MLSAreaMinor.match(regex)?.length ?? 0) == 0) &&
              (!item.SubdivisionName ||
                (item.SubdivisionName.match(regex)?.length ?? 0) == 0)
            ) {
              return false
            }
            break
          default:
            if (keysWithMinMax.includes(key)) {
              if (query[key]["min"] !== null && item[key] < query[key]["min"]) {
                return false
              }
              if (query[key]["max"] !== null && item[key] > query[key]["max"]) {
                return false
              }
            } else if (
              !item[key] &&
              item[key] !== 0 &&
              query[key] !== item[key]
            ) {
              return false
            } else if (!query[key].includes(item[key])) {
              return false
            }
        }
      }
      return true
    })
    return filteredData
  }

  let initialFilteredData = []

  if (
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("places_state" + location.key)
  ) {
    initialFilter = JSON.parse(
      localStorage.getItem("places_state" + location.key)
    ).filter
    initialQuery = buildFilter(initialFilter)
    initialFilteredData = filterData(allPosts, initialQuery)

    let currentKey = 0
    let returnedVal
    do {
      returnedVal = localStorage.key(currentKey)
      if (returnedVal && returnedVal.includes("places_state")) {
        localStorage.removeItem(returnedVal)
      }

      currentKey++
    } while (returnedVal)
  } else if (selectedMlsAreaMajor) {
    initialFilter = cloneDeep(initialFilter)
    initialFilter.MLSAreaMajor = [selectedMlsAreaMajor]
    initialQuery = buildFilter(initialFilter)
    initialFilteredData = filterData(allPosts, initialQuery)
  } else if (location.state) {
    if (location.state.homeFilter) {
      initialFilter = location.state.homeFilter
      initialQuery = buildFilter(initialFilter)
      initialFilteredData = filterData(allPosts, initialQuery)
    }
  }

  const [state, setState] = useState({
    filteredData: initialFilteredData,
    query: initialQuery,
    filter: initialFilter,
  })

  const handleOurPropertiesOnlyInputChange = event => {
    if (event.target.value && event.target.checked) {
      filter.ListOfficeMlsId.length = 0
      filter.ListOfficeMlsId.push(event.target.value)
    } else {
      filter.ListOfficeMlsId.length = 0
    }

    updateState()
  }

  const handleNewConstructionInputChange = event => {
    if (event.target.value && event.target.checked) {
      filter.PropertyCondition.length = 0
      filter.PropertyCondition.push(event.target.value)
    } else {
      filter.PropertyCondition.length = 0
    }

    updateState()
  }

  const handleExcludeUnderContractInputChange = event => {
    if (event.target.value && event.target.checked) {
      filter.StandardStatus.length = 0
      filter.StandardStatus.push(event.target.value)
    } else {
      filter.StandardStatus.length = 0
    }

    updateState()
  }

  const handleHasVirtualTourInputChange = event => {
    if (event.target.value && event.target.checked) {
      filter.VirtualTourURLUnbranded.length = 0
      filter.VirtualTourURLUnbranded.push(event.target.value)
    } else {
      filter.VirtualTourURLUnbranded.length = 0
    }

    updateState()
  }

  const handleTextInputChange = event => {
    /*if (event.target.value && event.target.value !== "") {
      filter.ListingId.length = 0
      filter.ListingId.push(event.target.value)
    } else {
      filter.ListingId.length = 0
    }*/
    filter.Keyword = event.target.value
    updateState()
  }

  const handleLocationInputChange = event => {
    if (event.target.value === "Snowmass Village") {
      setDynamicLocation("snow-village")
    } else if (event.target.value === "Woody Creek") {
      setDynamicLocation("wood-creek")
    } else if (event.target.value === "Old Snowmass") {
      setDynamicLocation("old-snow")
    } else if (event.target.value === "Missouri Heights") {
      setDynamicLocation("missouri")
    } else if (event.target.value === "Carbondale") {
      setDynamicLocation("carbondale")
    } else if (event.target.value === "Redstone/Crystal Valley") {
      setDynamicLocation("redstone")
    } else if (event.target.value === "Glenwood") {
      setDynamicLocation("glenwood")
    } else if (event.target.value === "Brush Creek Village") {
      setDynamicLocation("brush-village")
    } else {
      setDynamicLocation("location")
    }
    if (event.target.value && event.target.value !== "") {
      filter.MLSAreaMajor.length = 0
      if (event.target.value !== "all") {
        filter.MLSAreaMajor.push(event.target.value)
      }

      filter.MLSAreaMinor.length = 0
      if (event.target.value === "all") {
        setAllAreas([])
      } else {
        setAllAreas(allLocations[event.target.value])
      }
      setArea("")
    } else {
      filter.MLSAreaMajor.length = 0
    }

    updateState()
  }

  const handleAreaInputChange = event => {
    if (event.target.value === "") {
      setdynamicArea("area")
    } else {
      setdynamicArea("")
    }

    if (event.target.value && event.target.value !== "") {
      filter.MLSAreaMinor.length = 0
      setArea(event.target.value)
      filter.MLSAreaMinor.push(event.target.value)

      filter.SubdivisionName.length = 0
      if (event.target.value === "all") {
        setAllNeighborhoods([])
      } else {
        setAllNeighborhoods(allNeighborhoodsByArea[event.target.value])
      }
      setNeighborhood("")
    } else {
      setArea("")
      filter.MLSAreaMinor.length = 0
    }

    updateState()
  }

  const handleNeighborhoodInputChange = event => {
    if (event.target.value && event.target.value !== "") {
      filter.SubdivisionName.length = 0
      filter.SubdivisionName.push(event.target.value)
      setNeighborhood(event.target.value)
    } else {
      setNeighborhood("")
      filter.SubdivisionName.length = 0
    }

    updateState()
  }

  const handlePriceMinInputChange = minValue => {
    if (minValue && minValue !== minPriceSliderValue.toString()) {
      filter.ListPrice.min = null
      filter.ListPrice.min = minValue
    } else {
      filter.ListPrice.min = null
    }

    updateState()
  }

  const handlePriceMaxInputChange = maxValue => {
    if (maxValue && maxValue !== maxPriceSliderValue.toString()) {
      filter.ListPrice.max = null
      filter.ListPrice.max = maxValue
    } else {
      filter.ListPrice.max = null
    }

    updateState()
  }

  const handleSquareFeetMinInputChange = minValue => {
    if (minValue && minValue !== minSquareFeetSliderValue.toString()) {
      filter.LivingArea.min = null
      filter.LivingArea.min = minValue
    } else {
      filter.LivingArea.min = null
    }

    updateState()
  }

  const handleSquareFeetMaxInputChange = maxValue => {
    if (maxValue && maxValue !== maxSquareFeetSliderValue.toString()) {
      filter.LivingArea.max = null
      filter.LivingArea.max = maxValue
    } else {
      filter.LivingArea.max = null
    }

    updateState()
  }

  const handleLotSizeAcresMinInputChange = minValue => {
    if (minValue && minValue !== minLotSizeAcresSliderValue.toString()) {
      filter.LotSizeAcres.min = null
      filter.LotSizeAcres.min = minValue
    } else {
      filter.LotSizeAcres.min = null
    }

    updateState()
  }

  const handleLotSizeAcresMaxInputChange = maxValue => {
    if (maxValue && maxValue !== maxLotSizeAcresSliderValue.toString()) {
      filter.LotSizeAcres.max = null
      filter.LotSizeAcres.max = maxValue
    } else {
      filter.LotSizeAcres.max = null
    }

    updateState()
  }

  const handleBedsInputChange = event => {
    if (event.target.value && event.target.value !== "") {
      filter.BedroomsTotal.min = null
      filter.BedroomsTotal.min = event.target.value
    } else {
      filter.BedroomsTotal.min = null
    }

    updateState()
  }

  const handleBathsInputChange = event => {
    if (event.target.value && event.target.value !== "") {
      filter.BathroomsTotalInteger.min = null
      filter.BathroomsTotalInteger.min = event.target.value
    } else {
      filter.BathroomsTotalInteger.min = null
    }

    updateState()
  }

  const handleTypeInputChange = event => {
    if (event.target.value === "Fractional") {
      setdynamicType("fraction")
    } else if (event.target.value === "RES Vacant Land") {
      setdynamicType("res")
    } else if (event.target.value === "Residential") {
      setdynamicType("residential")
    } else if (event.target.value.includes("Commercial")) {
      setdynamicType("commercial")
    } else {
      setdynamicType("type")
    }

    if (event.target.value && event.target.value !== "") {
      filter.PropertyType.length = 0
      filter.PropertyType.push(event.target.value)
    } else {
      filter.PropertyType.length = 0
    }

    updateState()
  }

  const handleGarageInputChange = event => {
    if (
      event.target.value === "" ||
      event.target.value === "1 Car" ||
      event.target.value === "2 Car"
    ) {
      setdynamicGarage("garage")
    } else {
      setdynamicGarage("")
    }
    if (event.target.value && event.target.value !== "") {
      filter.ParkingFeatures.length = 0
      filter.ParkingFeatures.push(event.target.value)
    } else {
      filter.ParkingFeatures.length = 0
    }

    updateState()
  }

  const handleStyleInputChange = event => {
    if (
      event.target.value === "" ||
      event.target.value === "Cabin" ||
      event.target.value === "Other" ||
      event.target.value === "Ranch"
    ) {
      setdynamicStyle("style")
    } else if (
      event.target.value === "Split Level" ||
      event.target.value === "Two Story" ||
      event.target.value === "Victorian"
    ) {
      setdynamicStyle("split")
    } else if (event.target.value === "Contemporary") {
      setdynamicStyle("contemp")
    }
    if (event.target.value && event.target.value !== "") {
      filter.ArchitecturalStyle.length = 0
      filter.ArchitecturalStyle.push(event.target.value)
    } else {
      filter.ArchitecturalStyle.length = 0
    }

    updateState()
  }

  const saveStateForCurrentKey = () => {
    localStorage.setItem(
      "places_state" + location.key,
      JSON.stringify({
        filter,
        selectedArea,
        selectedNeighborhood,
        showAdvancedFilter,
        sortBy: selectedSortBy.title,
        page: pageNumber,
      })
    )
  }

  const handleSortByInputChange = event => {
    setSortBy(getFunctionBySortName(event.target.value))
  }

  const updateState = () => {
    const query = buildFilter(filter)
    const filteredData = filterData(allPosts, query)
    setState({
      query,
      filteredData,
      filter,
    })

    setPageNumber(0)
  }

  function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      const val1 = object1[key]
      const val2 = object2[key]
      const areObjects = isObject(val1) && isObject(val2)
      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false
      }
    }

    return true
  }

  function isObject(object) {
    return object != null && typeof object === "object"
  }

  const { filteredData, query, filter } = state
  const hasSearchResults =
    filteredData &&
    !deepEqual(query, emptyQuery) &&
    !deepEqual(filter, emptyFilter)
  const posts = hasSearchResults ? filteredData : allPosts
  posts.sort(selectedSortBy.func)

  const postsLength = posts.length

  const [pageNumber, setPageNumber] = useState(initialPage)

  const listingsPerPage = 25
  const listingsVisited = pageNumber * listingsPerPage

  const displayListings = posts
    .slice(listingsVisited, listingsVisited + listingsPerPage)
    .map(item => {
      return (
        <Link
          className="post-link listing-card"
          to={item.ListingId + "-" + item.parsedAddress}
          onClick={saveStateForCurrentKey}
        >
          {item.StandardStatus === "Pending" && (
            <div className="flagBox">Pending</div>
          )}
          <div className="image-holder">
            <LazyLoadImage
              alt="property"
              threshold={250}
              src={item.PrefferedPhoto.replace("WIDTH", 800).replace(
                "HEIGHT",
                800 / 1.5
              )}
            />
          </div>
          <div className="first-info">
            <h3 className="h3-header">
              ${new Intl.NumberFormat().format(item.ListPrice)}
            </h3>
            <div className="separator">|</div>
            {item.BedroomsTotal === null ? null : (
              <div className="first-info-inner">
                <IoIosBed className="icon-bed" />
                {item.BedroomsTotal}
              </div>
            )}
            {item.BathroomsTotalInteger === null ? null : (
              <div className="first-info-inner">
                <FaBath className="icon-common" />
                {item.BathroomsTotalInteger}
              </div>
            )}
            {item.LivingArea === null ? null : (
              <div className="first-info-inner">
                <FaRulerCombined className="icon-common" />
                {new Intl.NumberFormat().format(item.LivingArea)} SF
              </div>
            )}
          </div>
          <div className="inner-location">
            <FaMapMarkerAlt className="icon-location" />{" "}
            {item.UnparsedAddress.toUpperCase()}
          </div>
        </Link>
      )
    })

  const pageCount = Math.ceil(posts.length / listingsPerPage)
  const changePage = ({ selected }) => {
    window.scrollTo(0, 0)
    setPageNumber(selected)
  }

  const minSquareFeetSliderValue = 0
  const maxSquareFeetSliderValue = 5000
  const minLotSizeAcresSliderValue = 0
  const maxLotSizeAcresSliderValue = 25

  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return (
      <div className="hidden">
        {allPosts?.[0] &&
          allPosts.map((item, index) => (
            <a
              key={index}
              href={`/places/${item.ListingId}-${item.parsedAddress}`}
            ></a>
          ))}
      </div>
    )
  }

  return (
    <>
      <div id="filter-container">
        <h1 className="h1-header">{placesTitle}</h1>
        <div id="filter-options" className="filter-places">
          <div
            id="left-outside-filter-option"
            className="outside-filter-option"
          >
            <div className="border-filter">
              <select
                className={dynamicLocation}
                name="location"
                id="location"
                onChange={handleLocationInputChange}
                defaultValue={
                  selectedMlsAreaMajor
                    ? selectedMlsAreaMajor
                    : initialFilter && initialFilter.MLSAreaMajor.length >= 1
                      ? initialFilter.MLSAreaMajor[0]
                      : ""
                }
              >
                <option selected disabled value="">
                  LOCATION
                </option>
                <option value="all">All</option>
                {allLocation.map(item => {
                  return <option value={item}>{item}</option>
                })}
              </select>
            </div>
            <div className="borderless-filter">
              <select
                className={dynamicArea}
                name="area"
                id="area"
                value={selectedArea}
                onChange={handleAreaInputChange}
              >
                <option selected disabled value="">
                  AREA
                </option>
                <option value="">All</option>
                {allAreas.map(area => {
                  return <option value={area}>{area}</option>
                })}
              </select>
            </div>
          </div>
          <div id="middle-filter-option">
            <span>PRICE</span>
            <InputSlider
              min={minPriceSliderValue}
              max={maxPriceSliderValue}
              existingMin={parseInt(initialFilter.ListPrice.min)}
              existingMax={parseInt(initialFilter.ListPrice.max)}
              step={priceStepSliderValue}
              valueOutputFormat={value =>
                "$" + new Intl.NumberFormat().format(value)
              }
              minInputChange={handlePriceMinInputChange}
              maxInputChange={handlePriceMaxInputChange}
            />
          </div>
          <div
            id="right-outside-filter-option"
            className="outside-filter-option"
          >
            <div className="borderless-filter">
              <select
                name="beds"
                id="beds"
                className="beds"
                onChange={handleBedsInputChange}
                defaultValue={
                  initialFilter && initialFilter.BedroomsTotal.min
                    ? initialFilter.BedroomsTotal.min
                    : ""
                }
              >
                <option selected disabled value="">
                  BEDS
                </option>
                <option value="">0+</option>
                <option value="1">1+</option>
                <option value="2">2+</option>
                <option value="3">3+</option>
                <option value="4">4+</option>
                <option value="5">5+</option>
                <option value="6">6+</option>
                <option value="7">7+</option>
                <option value="8">8+</option>
              </select>
            </div>
            <div className="border-filter">
              <select
                name="baths"
                id="baths"
                className="baths"
                onChange={handleBathsInputChange}
                defaultValue={
                  initialFilter && initialFilter.BathroomsTotalInteger.min
                    ? initialFilter.BathroomsTotalInteger.min
                    : ""
                }
              >
                <option selected disabled value="">
                  BATHS
                </option>
                <option value="">0+</option>
                <option value="1">1+</option>
                <option value="2">2+</option>
                <option value="3">3+</option>
                <option value="4">4+</option>
                <option value="5">5+</option>
                <option value="6">6+</option>
                <option value="7">7+</option>
                <option value="8">8+</option>
              </select>
            </div>
          </div>
        </div>
        <div className="middle-options">
          <input
            className="search"
            type="text"
            placeholder="Search by keyword or MLS #"
            onChange={handleTextInputChange}
          ></input>
          <span className="or-span">OR</span>
          <span className="input-span">
            <input
              type="checkbox"
              id="ourPropertiesOnly"
              name="ourPropertiesOnly"
              value={process.env.OFFICE_MLS_ID}
              defaultChecked={
                initialFilter &&
                initialFilter.ListOfficeMlsId &&
                initialFilter.ListOfficeMlsId.length > 0
              }
              onChange={handleOurPropertiesOnlyInputChange}
            />
            Our Properties Only
          </span>
          <span
            className="toggle-span"
            aria-hidden="true"
            onClick={toggleAdvancedFilterDisplay}
          >
            {showAdvancedFilter ? `Hide Power Search` : `Power Search`}{" "}
            &nbsp;&lt;&lt;
          </span>
        </div>
      </div>
      {showAdvancedFilter ? (
        <div id="filter-options" className="filter-places">
          <div
            id="left-outside-filter-option"
            className="outside-filter-option"
          >
            <div className="border-filter">
              <select
                className={dynamicNeighbor}
                name="neighborhood"
                id="neighborhood"
                value={selectedNeighborhood}
                onChange={handleNeighborhoodInputChange}
              >
                <option selected disabled value="">
                  NEIGHBORHOOD
                </option>
                <option value="">All</option>
                {allNeighborhoods.map(neighborhood => {
                  return <option value={neighborhood}>{neighborhood}</option>
                })}
              </select>
            </div>
            <div className="borderless-filter">
              <select
                className={dynamicType}
                name="type"
                id="type"
                defaultValue={
                  initialFilter && initialFilter.PropertyType.length >= 1
                    ? initialFilter.PropertyType[0]
                    : ""
                }
                onChange={handleTypeInputChange}
              >
                <option selected disabled value="">
                  TYPE
                </option>
                <option value="">All</option>
                {allTypes.map(type => {
                  return <option value={type}>{type}</option>
                })}
              </select>
            </div>
          </div>
          <div id="middle-filter-option">
            <span>SQUARE FEET</span>
            <InputSlider
              valueOutputFormat={value => new Intl.NumberFormat().format(value)}
              step={100}
              min={minSquareFeetSliderValue}
              max={maxSquareFeetSliderValue}
              minInputChange={handleSquareFeetMinInputChange}
              maxInputChange={handleSquareFeetMaxInputChange}
            />
          </div>
          <div
            id="right-outside-filter-option"
            className="outside-filter-option"
          >
            <div className="borderless-filter">
              <select
                className={dynamicStyle}
                name="style"
                id="style"
                onChange={handleStyleInputChange}
                defaultValue={
                  initialFilter && initialFilter.ArchitecturalStyle.length >= 1
                    ? initialFilter.ArchitecturalStyle[0]
                    : ""
                }
              >
                <option selected disabled value="">
                  STYLE
                </option>
                <option value="">All</option>
                {allArchitecturalStyles.map(style => {
                  return <option value={style}>{style}</option>
                })}
              </select>
            </div>
            <div className="border-filter">
              <select
                className={dynamicGarage}
                name="garage"
                id="garage"
                onChange={handleGarageInputChange}
                defaultValue={
                  initialFilter && initialFilter.ParkingFeatures.length >= 1
                    ? initialFilter.ParkingFeatures[0]
                    : ""
                }
              >
                <option selected disabled value="">
                  GARAGE
                </option>
                <option value="">All</option>
                {allParkingTypes.map(garage => {
                  return <option value={garage}>{garage}</option>
                })}
              </select>
            </div>
          </div>
        </div>
      ) : null}
      {showAdvancedFilter ? (
        <div className="places-options">
          <span>
            <input
              type="checkbox"
              id="newConstruction"
              name="newConstruction"
              value="New Construction"
              defaultChecked={
                initialFilter &&
                initialFilter.PropertyCondition &&
                initialFilter.PropertyCondition.length > 0
              }
              onChange={handleNewConstructionInputChange}
            />
            New Construction
          </span>
          <span>
            <input
              type="checkbox"
              id="excludeUnderContract"
              name="excludeUnderContract"
              value="Active"
              defaultChecked={
                initialFilter &&
                initialFilter.StandardStatus &&
                initialFilter.StandardStatus.length > 0
              }
              onChange={handleExcludeUnderContractInputChange}
            />
            Exclude Under Contract
          </span>
          <span>
            <input
              type="checkbox"
              id="hasVirtualTour"
              name="hasVirtualTour"
              value="XYZ"
              defaultChecked={
                initialFilter &&
                initialFilter.VirtualTourURLUnbranded &&
                initialFilter.VirtualTourURLUnbranded.length > 0
              }
              onChange={handleHasVirtualTourInputChange}
            />
            Has Virtual Tour
          </span>
        </div>
      ) : null}
      <h1 className="subhead places-list-header">{searchTitleCaption}</h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 result-info">
        <div></div>

        {displayListings.length === 0 ? (
          <div className="listing-list">{postsLength} Listings</div>
        ) : (
          <div className="listing-list">
            {listingsVisited + 1} - {listingsVisited + displayListings.length}
            &nbsp;&nbsp;of&nbsp;&nbsp;{postsLength} Listings
          </div>
        )}
        <div className="sort-filter">
          <span>Sort By: </span>
          <select
            onChange={handleSortByInputChange}
            defaultValue={initialSortBy}
            id="sortBySelect"
          >
            <option value="price_high">Price: High To Low</option>
            <option value="price_low">Price: Low To High</option>
            <option value="date_recently_added">Most Recently</option>
          </select>
        </div>
      </div>
      <div className="containers">
        <div className="list-container">{displayListings}</div>
      </div>
      {displayListings.length === 0 ? null : (
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationButtons"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            initialPage={pageNumber}
          />
        </div>
      )}
    </>
  )
}

export default Search
